import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';

const PromptComponent = ({ when }) => {
  const { t } = useTranslation();
  const prompt = t('prompt', { returnObjects: true });
  return (
    <Prompt
      when={when}
      message={prompt.message}
    />
  );
};

PromptComponent.propTypes = {
  when: PropTypes.bool.isRequired,
};

export default PromptComponent;