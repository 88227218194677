export default {
  translations: {
    general: {
      buttons: {
        next: 'Siguiente',
        help: 'Ayuda',
        notAllow: 'No permitir',
        select: 'Seleccionar',
        startTask: 'comenzar tarea',
        continueStudy: 'Continuar estudio',
        finishStudy: 'Terminar estudio',
        back: 'atrás',
        accept: 'aceptar',
      },
    },
    prompt: {
      message: '¿Estás seguro que deseas salir? Si sales, no podrás completar el estudio.',
    },
    intro: {
      welcome: {
        title: '¡Bienvenido!',
        subtitle:
          'Estás a punto de participar en un estudio de mercado anónimo, por favor ten en cuenta:',
        lists: [
          `1. El estudio consiste en una o más tareas donde te pediremos que 
        veas un contenido como normalmente lo harías en tu casa.`,
          '2. Todos los datos que recolectamos son anónimos y privados.',
        ],
        textPromotional: 'Confirmo que tengo más de 18 años de edad',
        privacity: '',
        tc: ' Términos y Condiciones',
        linkTerms: 'https://www.synapbox.com/terminos-y-condiciones/',
        linkPrivacity: 'https://www.synapbox.com/aviso-de-privacidad/',
        lastText: ' y he leído las',
        pp: ' Políticas de Privacidad',
        btnStart: 'COMENZAR',
        chromeComputer: 'Para completar este estudio debes de abrirlo usando el computador.',
        otherBrowserComputer: 'Para completar este estudio,  debes de abrirlo usando el computador y usando el navegador Google Chrome. Lo puedes descargar haciendo clic ',
        otherBrowserMobile: 'Para completar este estudio,  debes de abrirlo usando el móvil y usando el navegador Google Chrome. Lo puedes descargar haciendo clic ',
        otherBrowser: 'Para completar este estudio,  debes de abrirlo usando el navegador Google Chrome. Lo puedes descargar haciendo clic ',
        anchorText: 'aquí',
        linkChrome: 'https://www.google.com/intl/es_mx/chrome/',
      },
      lobby: {
        title: '¡Gracias por tu tiempo, ahora cuéntanos de ti!',
        subtitle: 'Por favor responde las siguientes preguntas.',
      },
      validations: {
        title: 'Ten presente los siguientes tips para completar con éxito la evaluación',
        titleBar:
          'En pocos segundos daremos inicio a la evaluación y la cámara web de tu computador será encendida.',
        sunglases: 'No uses lentes oscuros.',
        face: 'Tu rostro viendo la pantalla.',
        lamp: 'Tu rostro bien iluminado.',
        screen: 'Presta atención a la pantalla',
      },
      calibration: {
        title: 'New title',
      },
      thanks: {
        title: 'MUCHAS GRACIAS POR TU TIEMPO.',
        titlePaused: 'MUCHAS GRACIAS POR TU INTERÉS.',
        titleErrorInit: 'EL LINK QUE UTILIZASTE PARA INGRESAR A ESTE ESTUDIO NO ES VÁLIDO',
        titleErrorUnknow: 'ALGO INESPERADO ACABA DE SUCEDER.',
        pharagraph:
          'La evaluación ya ha concluido, el número de participantes se completo satisfactoriamente. Te invitamos a participar en futuras evaluaciones.',
        pharagraphTerminate:
          'Muchas gracias por tu tiempo. Lamentablemente tu perfil no cumple con los requisitos que  en esta ocasión buscamos para continuar con el estudio.',
        pharagraphPaused:
          'Esta evaluación esta actualemnte en pausa. Intenta de nuevo en un par de horas.',
        pharagraphClosed: 'Esta evaluación ya fue cerrada.',
        pharagraphFinallyPaused: 'Siéntete libre de cerrar esta ventana.',
        pharagraphError:
          'Por favor haz clic en el siguiente link para ponerte en contacto con nuestro equipo de soporte:',
        pharagraphErrorUnknown: 'Por favor haz clic en el siguiente link para comenzar de nuevo:',
        pharagraphFinally: 'Siéntete libre de cerrar esta ventana.',
        pharagraphFinallyError: '¡Muchas gracias!',
        pharagraphCompleted: 'El estudio ha sido completado. Tu ID de participante es:',
        emailSOS: 'sos@synapbox.com',
        linkText: 'https://gate.synapbox.com/?cc=WDnsdu&amp;pc=AWS',
      },
    },
    questionOptions: {
      simple: 'Selecciona una opción',
      multiple: 'Selecciona una o más opciones',
      messageHeader: 'Para continuar, por favor responde a la pregunta',
      finishQuestionnaire: 'Has terminado el cuestionario',
      minCharacteres: 'Mínimo de caracteres requeridos:',
      errors: {
        boxEmpty: 'Debes escribir algo',
        minCharacteres: 'Debes escribir algo más largo',
        selectEmpty: 'Debes seleccionar por lo menos una opción',
        orderNotFinish:
          'Debes seleccionar y arrastrar todos los elementos a la columna de la derecha',
      },
      scaleQuestion: {
        leftRating: 'Totalmente en desacuerdo',
        rightRating: 'Totalmete de acuerdo',
        errors: {
          sliderNotTouched: 'Debes seleccionar y mover el deslizador por lo menos una vez',
        },
      },
      importanceQuestion: {
        dragMessage: 'Selecciona y arrastra las opciones a este espacio en el orden que desees',
      },
    },
    errorPage: {
      title: 'Algunas veces no es tan malo perderse…',
      titleHeader: 'Página de error - Código de error 404',
      errorTitle: 'CÓDIGO DE ERROR',
      errorCode: '404',
      errorContent: 'La página que solicitas no existe o fue eliminada.',
      errorBtnBack: 'Regresar al estudio',
      errorGeneralBtnBack: 'Atrás',
      errorGeneralTitle: 'Disculpa',
      errorGeneralText: 'Parece que algo salió mal.',
    },
    tasks: {
      title: 'Tareas a realizar',
      subtitle: 'Tienes que completar todas las tareas para concluir correctamente el estudio',
      time: 'Tiempo para completar la tarea:',
      instruction: 'Instrucción:',
      success:
        'Haz completado con éxito todas las tareas y todos los resultados fueron enviados correctamente.',
      continue:
        'Continua para poder redirigirte a tu panel y puedas concluir con tu participanción.',
      thanks: '¡Muchas gracias por tu tiempo!',
    },
    taskMedia: {
      help: 'ayuda',
      content: 'Contenido:',
      ContentImage: 'Image',
      ContentVideo: 'Video',
      task: 'Tarea',
      loadingContent: 'Cargando contenido...',
      loadingContentValidation: 'Validando tu atención en la última tarea',
      browserErrorTag: 'Tu navegador no soporta la etiqueta video',
      loadingTitle:
        'Enviando resultados de la tarea a nuestros servidores, por favor no cierres la ventana…',
    },
    permissions: {
      title: 'Necesitamos usar tu cámara',
      message: 'Es importante que nos permitas utilizar la cámara web de tu equipo',
      tips: [
        '1. Necesitamos activar tu cámara web para registrar tu interacción con los contenidos que vamos a mostrarte.',
        '2. Toda la información es anónima y tu identidad nunca es vista o analizada.',
        '3. Una vez hayas terminado el estudio, la cámara será apagada.',
      ],
      button: {
        accept: 'PERMITIR USO DE CÁMARA WEB',
      },
    },
    modal: {
      problemValidation: 'Hay un problema con el sensor',
      titleErrorNoFace: 'Problemas con los sensores',
      noFaceDetection: 'No reconocemos tu cara, por favor intenta de nuevo',
      noMedia: 'La tarea no tiene contenido multimedia',
      noAcceptPermission:
        'Debes aceptar los permisos de la cámara, verifica los permisos de la cámara y vuelva a cargar la página',
      noCameraPermission:
        'Sin permisos de cámara, verifique los permisos de su cámara y vuelva a cargar la página',
    },
    calibration: {
      eyeCalibration: {
        title: 'Calibración',
        tip1: '1.- Haz clic 5 veces en cada punto naranja, hasta que cambie a verde mientras lo observas fijamente',
        tip2: '2.- Después mira fijamente el punto central durante 5 segundos y sin mover tu ratón',
        btn: 'Aceptar'
      },
      measurement: {
        title: 'Calculando precisión',
        message:
          'Mira fijamente el punto central que se mostrará a continuación durante 5 segundos y sin mover tu ratón.',
        btn: 'Aceptar',
      },
      initial: {
        title: 'Pasos para calibrar tu cámara web',
        message: [
          'Tu cara debe ubicarse completamente dentro del cuadro hasta que éste se vuelva verde.',
          'Luego gira tu cabeza a la derecha y a la izquierda y finalmente mueve tu cabeza arriba y abajo.',
          'Cuando la máscara se posicione correctamente sobre tu cara, inicia la calibración haciendo clic en “COMENZAR CALIBRACIÓN”.',
          'Si la máscara no se posiciona correctamente, repite todas las instrucciones.',
        ],
        btn: 'COMENZAR CALIBRACIÓN',
      },
      complete: {
        title: 'CALIBRACIÓN COMPLETADA',
        message: 'Haz clic en Aceptar para continuar con el estudio.',
        btn: 'Aceptar',
      },
      error: {
        title: 'Debes completar la tarea nuevamente ya que no se cumplieron algunas condiciones.',
        message:
          'Por favor asegúrate de: 1) tu cara está de frente a la pantalla; 2) evita mover la cabeza muy rápido; 3) tener buenas condiciones de iluminación; 4) no usar lentes, sombreros o gorras.',
      },
    },
    validation: {
      modal: {
        title: 'Validación exitosa',
        message: 'Puedes continuar con el estudio',
      },
      browser: {
        type: 'Este estudio sólo está disponible para el navegador Google Chrome',
        version:
          'La versión de Google Chrome debe ser igual o mayor a la version 72. Por favor actualiza Chrome para completar el estudio',
        platform:
          'Este estudio no se puede realizar desde un celular o tableta. Por favor usa un computador portátil o de escritorio',
      },
    },
    networkStatus: {
      online: 'Su dispositivo está conectado a Internet.',
      slow: 'Su conexión a internet es lenta',
      offline: 'Su dispositivo perdió la conexión a Internet',
    },
    dialogPlayVideo: {
      title: 'Estamos listos',
      message: 'Haz clic en el botón verde para comenzar',
      button: 'Reproducir contenido',
    },
    errorValidateTester: {
      title: 'Error',
      text: 'Tenemos problemas para validar la tarea, verifique su conexión a Internet e intente nuevamente',
      button: 'Intentar de nuevo'
    },
    testerAlreadyFiltered: {
      title: 'Aviso',
      text: 'Ya has sido filtrado, no puedes continuar',
    },
  },
};
