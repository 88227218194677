import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GET_ERROR from './error.gql';
import getError from './errors';
import Dialog from '../Dialog';

const ErrorApollo = () => {
  const { data, client } = useQuery(GET_ERROR);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  const { error: { code } } = data;

  if (!code) return null;

  const errorData = getError(code);

  if (!errorData) return null;
  if (Object.keys(errorData).length === 0) return null;

  const {
    title, text, positiveButton,
  } = errorData;

  const cleanError = () => {
    client.writeData({
      data: {
        error: {
          open: false,
          type: null,
          message: null,
          status: null,
          code: null,
          path: null,
        },
      },
    });
  };

  let { action, text: PositiveText } = positiveButton;

  if (!positiveButton) {
    action = null;
    PositiveText = null;
  }

  return (
    <Dialog
      open
      icon="atention"
      title={t(title)}
      positiveButton={positiveButton}
      handleBtn={() => action({
        cleanError, history, location, params,
      })
      }
      t={t}
      textButton={PositiveText}
      size="md"
    >
      {text && (
      <div>
        {t(text)}
      </div>
      )}
    </Dialog>
  );
};

export default ErrorApollo;