import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '../ButtonComponent';
import TitleComponent from '../TitleComponent';


const Templete = (props) => {
  const {
    title, icon, children, onClick, disabled, textButton,
  } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitleComponent icon={icon}>{title}</TitleComponent>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="positive"
          onClick={onClick}
          disabled={disabled}
          size="large"
          fullWidth
        >
          {textButton}
        </Button>
      </Grid>
    </Grid>
  );
};

Templete.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  textButton: PropTypes.string.isRequired,
};

export default Templete;
