import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Honeybadger from 'honeybadger-js';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Mutation } from 'react-apollo';
import {
  Grid,
  Paper,
  Radio,
  FormGroup,
  RadioGroup,
  Checkbox,
  Box,
  FormControlLabel,
} from '@material-ui/core';
import Question from '../Question';
import ButtonGroup from '../ButtonGroup';
import ANSWER_MULTIPLE from './graphql';
import styles from './styles';

export class QuestionMultiple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question: props.question,
      testerid: props.testerid,
      answer: ['empty'],
      answerObject: [],
      disabledNext: true,
      isTerminate: [],
      isRequired: false,
      isSending: false,
      isRequiredGeneral: false,
    };
  }

  componentDidMount = () => {
    this.reloadGeneralRequired();
  };

  componentDidUpdate = (prevProps) => {
    const { question, testerid } = this.props;
    if (question.id !== prevProps.question.id) {
      const { options } = question.multiple;
      const answer = options.filter(item => item.required);
      this.updateState({
        question,
        testerid,
        answer: ['empty'],
        answerObject: [],
        isRequiredGeneral: answer.length > 0,
        isTerminate: [],
        isRequired: false,
        isSending: false,
        disabledNext: true,
      });
    }
  };

  componentWillUnmount = () => {
    const { question } = this.props;
    this.updateState({
      question,
      answer: [],
      answerObject: [],
      isRequiredGeneral: false,
      isTerminate: [],
      isRequired: false,
    });
  };

  setAnswerSimple = (e) => {
    const { value } = e.target;
    const { answerObject, question, answer } = this.state;
    const { options } = question.multiple;
    const optionQuestionSelected = options.find(opt => opt.id === value);
    const index = options.indexOf(optionQuestionSelected);
    answer[0] = value;
    answerObject[0] = options[index];
    const filters = this.vericationFilters(index);
    this.setState({
      question: { ...question, multiple: { options } },
      answer,
      ...filters,
      answerObject,

      disabledNext: answer.length === 0,
    });
  };

  vericationFilters = (index, idAdding, resetFilters) => {
    const { question } = this.state;
    const { options } = question.multiple;
    const { terminate, required } = options[index];
    let { isRequired } = this.state;
    const { isTerminate } = this.state;

    let newTerminate = Object.assign([], isTerminate);

    if (resetFilters) {
      newTerminate = [];
    }

    if (terminate) {
      if (idAdding) {
        newTerminate = [...newTerminate, `item-${index}`];
      } else {
        newTerminate = newTerminate.filter(item => item !== `item-${index}`);
      }
      isRequired = false;
    } else if (required) {
      isRequired = true;
    } else {
      isRequired = false;
    }
    return {
      isTerminate: newTerminate,
      isRequired,
    };
  };

  setAnswerMultiple = (e) => {
    const { value } = e.target;
    const { answerObject, question, answer } = this.state;
    const { options } = question.multiple;
    const optionQuestionSelected = options.find(opt => opt.id === value);
    const uniqueFromAllOptions = options.filter(opt => opt.unique);

    const index = options.indexOf(optionQuestionSelected);

    const isNewValueUnique = optionQuestionSelected.unique;

    let newAnswerObject = Object.assign([], answerObject);
    let newAnswer = Object.assign([], answer);
    let filters;

    const isNewValueChecked = newAnswer.includes(value);

    if (isNewValueUnique && !isNewValueChecked) {
      newAnswer = ['empty'];
      newAnswerObject = [];
    }

    const uniqueId = uniqueFromAllOptions.flatMap(item => item.id);

    const isUniqueChecked = newAnswer.some(item => uniqueId.includes(item));

    if (isUniqueChecked) {
      newAnswer = newAnswer.filter(
        answerItem => !uniqueId.includes(answerItem),
      );
      newAnswerObject = newAnswerObject.filter(
        answerItem => !uniqueId.includes(answerItem.id),
      );
    }

    const clearFilters = isNewValueUnique || isUniqueChecked;

    if (isNewValueChecked) {
      newAnswer = newAnswer.filter(answerItem => answerItem !== value);
      newAnswerObject = newAnswerObject.filter(
        answerItem => answerItem.id !== value,
      );
      filters = this.vericationFilters(index, false, clearFilters);
    } else {
      newAnswer.push(value);
      newAnswerObject.push(options[index]);
      filters = this.vericationFilters(index, true, clearFilters);
    }

    const minimumRequiredOptions = question.minimumRequiredOptions || 1;

    this.setState({
      question: { ...question, multiple: { options } },
      answer: newAnswer,
      ...filters,
      answerObject: newAnswerObject,
      disabledNext: newAnswer.length <= minimumRequiredOptions,
    });
  };

  reloadGeneralRequired = () => {
    const { question } = this.state;
    const { options } = question.multiple;
    const answer = options.filter(item => item.required);
    this.updateState({
      isRequiredGeneral: answer.length > 0,
    });
  };

  updateState = (newState) => {
    this.setState(newState);
  };

  next = async (answerMultiple) => {
    const {
      isTerminate, answerObject, answer, isRequiredGeneral,
    } = this.state;

    const {
      handlePrompt,
      redirectPanelTerminate,
      handleRedirectTerminate,
      question,
    } = this.props;

    const { minimumRequiredOptions } = question.multiple;

    const isTerminateStatus = isTerminate.length > 0;
    const isTerminatedObject = answerObject.filter(item => item.terminate).length > 0;
    try {
      handlePrompt(false);
      if (isTerminateStatus || isTerminatedObject) {
        await this.realNext(answerMultiple, true);
        if (redirectPanelTerminate === null) {
          handleRedirectTerminate('/messages/thanksterminate');
        } else {
          window.onbeforeunload = undefined;
          window.location.href = redirectPanelTerminate;
        }
      } else if (
        isRequiredGeneral
        && answerObject.filter(item => item.required).length === 0
      ) {
        await this.realNext(answerMultiple, true);
        if (redirectPanelTerminate === null) {
          handleRedirectTerminate('/messages/thanksrequired');
        } else {
          window.onbeforeunload = undefined;
          window.location.href = redirectPanelTerminate;
        }
      } else if (answerObject.length < minimumRequiredOptions) {
        await this.realNext(answerMultiple, true);
        if (redirectPanelTerminate === null) {
          handleRedirectTerminate('/messages/thanksterminate');
        } else {
          window.onbeforeunload = undefined;
          window.location.href = redirectPanelTerminate;
        }
      } else if (answer.length > 0) {
        this.setState(
          {
            isSending: true,
          },
          () => this.realNext(answerMultiple),
        );
      }
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error validation question multiple',
        name: 'next function',
        component: '<QuestionMultiple />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  };

  realNext = async (answerMultiple, filter = false) => {
    const { testerid, question, answer } = this.state;

    const multipleOptions = {};
    const multiple = question.multiple.options;

    multiple.forEach((multipleItem) => {
      multipleOptions[multipleItem.id] = multipleItem;
    });

    const jumps = answer.reduce((reduceJump, awnserItem) => {
      if (multipleOptions[awnserItem]?.jumpQuestionId) {
        reduceJump.push(multipleOptions[awnserItem].jumpQuestionId);
      }
      return reduceJump;
    }, []);

    const optionsQuestion = question.multiple.options;
    const { nextHandler } = this.props;
    const indexEmptyAnswer = answer.indexOf('empty');
    if (indexEmptyAnswer !== -1) {
      answer.splice(indexEmptyAnswer, 1);
    }
    const answerLabels = answer.map((answerId) => {
      const optionFind = optionsQuestion.find(
        option => option.id === answerId,
      );
      return optionFind.id;
    });
    try {
      await answerMultiple({
        variables: {
          tester: testerid,
          question: question.id,
          answer: answerLabels,
        },
      });

      let dynamicAnswers = JSON.parse(localStorage.getItem('dynamic-answers')) || {};
      dynamicAnswers = { ...dynamicAnswers, [question.id]: answerLabels };
      localStorage.setItem('dynamic-answers', JSON.stringify(dynamicAnswers));

      this.setState(
        {
          isSending: false,
        },
        () => nextHandler(filter, jumps, question.id, question.required),
      );
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error save question multiple',
        name: 'realNext function',
        component: '<QuestionMultiple />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  };

  render() {
    const { t } = this.props;
    const {
      question, isSending, disabledNext, answer,
    } = this.state;
    const optionsQuestion = question.multiple.options;
    const { classes } = this.props;


    return (
      <Mutation mutation={ANSWER_MULTIPLE}>
        {answerMultiple => (
          <Question
            questionType={question.questionType}
            question={question.title}
            image={{
              src: question.imageUrl,
              fileName: question.filename,
            }}
            handleEvent={() => this.next(answerMultiple)}
            disabledNext={disabledNext || isSending}
            loading={isSending}
            mediaFile={question.mediaFile}
            label={
              question.questionType === 'MULT_SINGLE'
                ? t('questionOptions.simple')
                : t('questionOptions.multiple')
            }
          >
            {question.questionType === 'MULT_SINGLE' ? (
              <Fragment>
                {optionsQuestion.length <= 5 ? (
                  //  SINGLE MENOS DE 5
                  <Box mb={2}>
                    <ButtonGroup
                      fullWidth
                      size="large"
                      color="primary"
                      activated={answer}
                      options={optionsQuestion}
                      handleClick={this.setAnswerSimple}
                    />
                  </Box>
                ) : (
                  //  SINGLE MASSS DE 5
                  <RadioGroup value={answer[0]} onChange={this.setAnswerSimple}>
                    <Grid container spacing={2} alignItems="stretch">
                      {optionsQuestion.map(opt => (
                        <Grid
                          item
                          xs={12}
                          md={optionsQuestion.length < 20 ? 6 : 4}
                        >
                          <Paper
                            elevation={0}
                            className={clsx([
                              classes.paperAnswer,
                              answer.includes(opt.id)
                                && classes.paperAnswerColor,
                            ])}
                          >
                            <Box display="flex" alignItems="center">
                              <Box className={classes.optionImageContainer}>
                                <FormControlLabel
                                  value={opt.id}
                                  control={<Radio color="primary" />}
                                  label={opt.label}
                                  className={
                                    optionsQuestion.length > 20
                                    && classes.optionItem
                                  }
                                  checked={answer.includes(opt.id)}
                                />
                              </Box>
                              {opt.imageUrl && !question.imageUrl && (
                                <Box mr={1} className={classes.image}>
                                  <img
                                    className={classes.optionImage}
                                    src={opt.imageUrl}
                                    alt={opt.filename}
                                  />
                                  </Box>
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {optionsQuestion.length <= 5 ? (
                  <Box mb={2}>
                    <ButtonGroup
                      fullWidth
                      size="large"
                      color="primary"
                      activated={answer}
                      options={optionsQuestion}
                      handleClick={this.setAnswerMultiple}
                    />
                  </Box>
                ) : (
                  //   M  A S  DE 5 SIMPLEE
                  <FormGroup alignItems="center">
                    <Grid container spacing={2} alignItems="stretch">
                      {optionsQuestion.map(opt => (
                        <Grid
                          item
                          xs={12}
                          md={optionsQuestion.length < 20 ? 6 : 4}
                        >
                          <Paper
                            elevation={0}
                            className={clsx([
                              classes.paperAnswer,
                              answer.includes(opt.id)
                                && classes.paperAnswerColor,
                            ])}
                          >
                            <Box display="flex" alignItems="center">
                              <Box className={classes.optionImageContainer}>
                                <FormControlLabel
                                  className={
                                    optionsQuestion.length > 20
                                    && classes.optionItem
                                  }
                                  control={(
                                    <Checkbox
                                      color="primary"
                                      checked={answer.includes(opt.id)}
                                      onChange={this.setAnswerMultiple}
                                      value={opt.id}
                                    />
                                  )}
                                  label={opt.label}
                                />
                                {opt.imageUrl && !question.imageUrl && (
                                  <Box mr={1} className={classes.image} style={{ marginRight: 8 }}>
                                    <img
                                      className={classes.optionImage}
                                      src={opt.imageUrl}
                                      alt={opt.filename}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                )}
              </Fragment>
            )}
          </Question>
        )}
      </Mutation>
    );
  }
}

QuestionMultiple.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    multiple: PropTypes.shape({
      minimumRequiredOptions: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
    mediaFile: PropTypes.shape({}),
    imageUrl: PropTypes.string,
    filename: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  testerid: PropTypes.string.isRequired,
  nextHandler: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  redirectPanelTerminate: PropTypes.string,
  handlePrompt: PropTypes.func.isRequired,
  handleRedirectTerminate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
QuestionMultiple.propTypes = {
  classes: PropTypes.shape({
    paperAnswer: PropTypes.string,
    paperAnswerColor: PropTypes.string,
    optionImage: PropTypes.string,
    optionImageContainer: PropTypes.string,
    image: PropTypes.string,
    optionItem: PropTypes.string,
  }).isRequired,
};

QuestionMultiple.defaultProps = {
  redirectPanelTerminate: null,
};

export default withStyles(styles)(
  withRouter(withTranslation()(QuestionMultiple)),
);
