export default {
  translations: {
    general: {
      buttons: {
        next: 'Próxima',
        help: 'Ajuda',
        notAllow: 'Não permitir',
        select: 'Selecione',
        startTask: 'começar a tarefa',
        continueStudy: 'Continue o estudo',
        finishStudy: 'Concluir o estudo',
        back: 'volta',
        accept: 'aceitar',
      },
    },
    prompt: {
      message: 'Tem certeza de que quer sair? Se você sair, não poderá concluir a pesquisa.',
    },
    intro: {
      welcome: {
        title: 'Bem vindo!',
        subtitle: 'Você está prestes a participar de uma pesquisa de mercado anônima, lembre-se:',
        lists: [
          `1. O estudo consiste em uma ou mais tarefas em que lhe pediremos para que você veja o conteúdo como faria normalmente em casa.`,
          '2. Todos os dados que coletamos são anônimos e privados.',
        ],
        textPromotional: 'Confirmo que tenho mais de 18 anos',
        privacity: '',
        tc: ' Termos e Condições',
        linkTerms: 'https://www.synapbox.com/terms-and-conditions',
        linkPrivacity: 'https://www.synapbox.com/privacy-policy',
        lastText: ' e li as',
        pp: ' Políticas de Privacidade',
        btnStart: 'INICIAR',
        chromeComputer: 'Para concluir este estudo, você deve abri-lo usando computador.',
        otherBrowserComputer: 'Para concluir este estudo, você deve abri-lo usando computador e usando o navegador Google Chrome. Você pode baixá-lo clicando',
        otherBrowserMobile: ' Para concluir este estudo, você deve abri-lo usando celular e usando o navegador Google Chrome. Você pode baixá-lo clicando',
        otherBrowser: 'Para concluir este estudo, você deve abri-lo usando o navegador Google Chrome. Você pode baixá-lo clicando ',
        anchorText: 'aqui',
        linkChrome: 'https://www.google.com/chrome/',
      },
      lobby: {
        title: 'Obrigado pelo seu tempo, agora conte-nos sobre você!',
        subtitle: 'Por favor, responda às seguintes perguntas.',
      },
      validations: {
        title: 'Tenha em mente as seguintes dicas para concluir com sucesso a avaliação',
        titleBar:
          'Em poucos segundos iniciaremos a avaliação e a webcam do seu computador será ligada.',
        sunglases: 'Não use óculos escuros.',
        face: 'Seu rosto assistindo a tela.',
        lamp: 'Seu rosto bem iluminado.',
        screen: 'Preste atenção na tela',
      },
      calibration: {
        title: 'New title',
      },
      thanks: {
        title: 'MUITO OBRIGADO PELO SEU TEMPO.',
        titlePaused: 'MUITO OBRIGADO PELO SEU INTERESSE.',
        titleErrorInit: 'O LINK QUE VOCÊ UTILIZA PARA ENTRAR NESTE ESTUDO NÃO É VÁLIDO',
        titleErrorUnknow: 'ALGO INESPERADO ACONTECEU.',
        pharagraph:
          'A avaliação já foi completada, o número de participantes foi completado satisfatoriamente. Nós convidamos você a participar de futuras avaliações.',
        pharagraphTerminate:
          'Seu perfil não atende aos requisitos para continuar com esta pesquisa.',
        pharagraphPaused:
          'Esta avaliação está atualmente em pausa. Tente novamente em algumas horas.',
        pharagraphClosed: 'Esta avaliação foi encerrada.',
        pharagraphFinallyPaused: 'Sinta-se à vontade para fechar esta janela.',
        pharagraphError:
          'Por favor, clique no link abaixo para entrar em contato com nossa equipe de suporte:',
        pharagraphErrorUnknown: 'Por favor, clique no seguinte link para começar de novo:',
        pharagraphFinally: 'Sinta-se à vontade para fechar esta janela.',
        pharagraphFinallyError: 'Obrigado!',
        pharagraphCompleted: 'O estudo foi finalizado. Seu ID de participante é:',
        emailSOS: 'sos@synapbox.com',
        linkText: 'https://gate.synapbox.com/?cc=WDnsdu&amp;pc=AWS',
      },
    },
    questionOptions: {
      simple: 'Selecione uma opção',
      multiple: 'Selecione uma ou mais opções',
      messageHeader: 'Para continuar, responda a pergunta',
      finishQuestionnaire: 'Você terminou o questionário',
      minCharacteres: 'Quantidade mínima de carácteres:',
      errors: {
        boxEmpty: 'Você deve escrever algo',
        minCharacteres: 'Você deveria escrever algo mais',
        selectEmpty: 'Você deve selecionar pelo menos uma opção',
        orderNotFinish:
          'Você deve selecionar e arrastar todos os elementos para a coluna à direita',
      },
      scaleQuestion: {
        leftRating: 'Discordo totalmente',
        rightRating: 'Totalmente de acordo',
        errors: {
          sliderNotTouched: 'Você deve selecionar e mover o controle deslizante pelo menos uma vez',
        },
      },
      importanceQuestion: {
        dragMessage: 'Selecione e arraste as opções para este espaço na ordem que você deseje',
      },
    },
    errorPage: {
      title: 'Às vezes não é tão ruim se perder ...',
      titleHeader: 'Página de erro - código de erro 404',
      errorTitle: 'CÓDIGO DE ERRO',
      errorCode: '404',
      errorContent: 'A página que você está solicitando não existe ou foi excluída.',
      errorBtnBack: 'Volte para a pesquisa',
      errorGeneralBtnBack: 'Voltar',
      errorGeneralTitle: 'Desculpas',
      errorGeneralText: 'Parece que algo deu errado.',
    },
    tasks: {
      title: 'Tarefas a realizar',
      subtitle: 'Você tem que completar todas as tarefas para completar a pesquisa corretamente',
      time: 'Hora de completar a tarefa:',
      instruction: 'Instrução:',
      success:
        'Você concluiu todas as tarefas com sucesso e todos os resultados foram enviados corretamente.',
      continue: 'Continue a redirecionar-se para o seu painel e conclua com sua participação.',
      thanks: 'Muito obrigado pelo seu tempo!',
    },
    taskMedia: {
      help: 'ajuda',
      content: 'Conteúdo:',
      ContentImage: 'Imagem',
      ContentVideo: 'Video',
      task: 'Tarefa',
      loadingContent: 'Carregando conteúdo',
      loadingContentValidation: 'Validando sua atenção na última tarefa',
      browserErrorTag: 'Seu navegador não suporta a tag de vídeo',
      loadingTitle:
        'Enviando resultados da tarefa para nossos servidores, por favor, não feche a janela…',
    },
    permissions: {
      title: 'É importante que você nos permita usar a webcam do seu computador',
      message: 'Vamos ativar sua webcam para continuar',
      tips: [
        '1. Precisamos ativar sua webcam para registrar sua interação com o conteúdo que vamos mostrar para você.',
        '2. Toda a informação é anônima e sua identidade nunca é vista ou analisada.',
        '3. Depois de terminar o estudo, a câmera será desligada.',
      ],
      button: {
        accept: 'PERMITIR O USO DA CÂMERA WEB',
      },
    },
    modal: {
      problemValidation: 'Existe um problema com o sensor de ',
      titleErrorNoFace: 'Problemas com os sensores',
      noFaceDetection: 'Nós não reconhecemos o seu rosto, por favor tente novamente',
      noMedia: 'A tarefa não possui conteúdo multimídia',
      noAcceptPermission:
        'Você deve aceitar as permissões da câmera, verificar as permissões da câmera e recarregar a página',
      noCameraPermission:
        'Sem permissões de câmera, verifique as permissões da sua câmera e recarregue a página',
    },
    calibration: {
      eyeCalibration: {
        title: 'calibração',
        tip1: '1.- Clique 5 vezes em cada ponto laranja, enquanto olha fixamente até ficar verde',
        tip2: '2.- Após disso, olhe fixamente para o ponto central por 5 segundos e sem mover o mouse',
        btn: 'Aceitar'
      },
      measurement: {
        title: 'Calculando medidas',
        message:
          'Por favor, olhe para o ponto no centro pelos próximos 5 segundos sem mover o mouse. Isso nos ajuda a medir a precisão de nossa calibração.',
        btn: 'Aceitar',
      },
      initial: {
        title: 'Etapas para calibrar sua webcam',
        message: [
          'Seu rosto deve ser colocado completamente dentro do quadro até ficar verde.',
          'Depois vire a cabeça para a direita e esquerda e, finalmente, mexa a cabeça para cima e para baixo.',
          'Quando a máscara estiver posicionada corretamente em seu rosto, inicie a calibração clicando em "INICIAR CALIBRAÇÃO".',
          'Se a máscara não estiver posicionada corretamente, repita todas as instruções. ',
        ],
        btn: 'INICIAR CALIBRAÇÃO',
      },
      complete: {
        title: 'CALIBRAÇÃO COMPLETA',
        message: 'Clique em OK para continuar a pesquisa',
        btn: 'Aceitar',
      },
      error: {
        title: 'Você deve concluir a tarefa novamente, pois algumas condições não foram atendidas.',
        message:
          'Por favor, certifique-se: 1) seu rosto está virado para a tela; 2) Evite mover a cabeça muito rapidamente; 3) ter boas condições de iluminação; 4) Não use óculos, chapéus ou bonés.',
      },
    },
    validation: {
      modal: {
        title: 'VALIDAÇÃO DO PARTICIPANTE',
        message: 'Validação bem sucedida Você pode continuar com a pesquisa',
      },
      browser: {
        type: 'Esta pesquisa somente está disponível para navegação no Google Chrome.',
        version:
          'A versão do Google Chrome deve ser igual ou superior a versão 72. Por favor atualize o Chrome para completar a pesquisa.',
        platform:
          'Esta pesquisa não pode ser realizada desde um celular ou tablet. Por favor use seu computador ou notebook.',
      },
    },
    networkStatus: {
      online: 'Seu dispositivo está conectado à Internet',
      slow: 'Sua conexão com a Internet está lenta',
      offline: 'O seu dispositivo perdeu, é conexão com a Internet',
    },
    dialogPlayVideo: {
      title: 'Estamos prontos',
      message: 'Clique no botão verde para começar',
      button: 'Reproduzir conteúdo',
    },
    errorValidateTester: {
      title: 'Error',
      text: 'Estamos com problemas para verificar a tarefa, verifique sua conexão com a internet e tente novamente',
      button: 'Tente novamente'
    },
    testerAlreadyFiltered: {
      title: 'Aviso prévio',
      text: 'Você já foi filtrado, não pode continuar',
    },
  },
};
