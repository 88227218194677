import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  activated: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  hightButton: {
    '& > button': {
      minHeight: '14.2rem',
      textTransform: 'none',
      padding: '1.6rem',
    },
  },
  imageContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.8rem',
    '& > img': {
      width: '100%',
      height: 'auto',
    },
  },
  hiddenImage: {
    display: 'none',
  },
  imageContainer2options: {
    width: '65%',
    height: 'auto',
  },
  answerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  text: {
    fontSize: '1.8rem',
    lineHeight: '1.6em',
    textTransform: 'none',
  },
}));

export default useStyles;