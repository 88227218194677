import React, { useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const QuestionTitle = ({ title }) => {
  const refTitle = useRef(null);

  useEffect(() => {
    if (!refTitle) return;
    refTitle.current.innerHTML = title;
  }, [title]);

  return (
    <Typography
      variant="subtitle1"
      color="primary"
      align="left"
      style={{
        fontWeight: 400,
        fontSize: title.length <= 500 ? 24 : 16,
      }}
    >
      <div ref={refTitle} />
    </Typography>
  );
};

QuestionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default QuestionTitle;