const errorList = {
  TESTER_ALREADY_FILTERED_FROM_STUDY: {
    trackError: true,
    title: 'testerAlreadyFiltered.title',
    text: 'testerAlreadyFiltered.text',
    // positiveButton: null,
    positiveButton: {
      text: 'Accept',
      action: ({ cleanError, history }) => {
        cleanError();
        history.push('/messages/thanksrequired');
      },
    },
  },
  RECORD_NOT_FOUND: {},
};

const getError = code => errorList[code];

export default getError;