import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import ItemList from '../ItemList';

const Wrapper = styled.div`
  border-radius: 0.5rem;
  position: relative;
  min-height: 5.2rem;
`;

const ElementFact = styled.div`
  display: 'block';
  border: dashed 0.2rem #919098;
  height: 5.2rem;
  width: 100%;
`;

const ColumnList = (props) => {
  const { columnId, options, quantityElements } = props;
  return (
    <Droppable droppableId={columnId}>
      {provider => (
        <Wrapper
          innerRef={provider.innerRef}
          {...provider.droppableProps}
          columnId={columnId}
          optionsLength={options.length}
        >
          {
            options.map((item, index) => {
              const key = `${columnId}-${item.id}`;
              return (
                <ItemList
                  key={key}
                  title={item.label}
                  index={index}
                  columnId={columnId}
                />
              );
            })
          }
          {(columnId === 'listOptionsOrder' && quantityElements > options.length) && (
            <ElementFact />
          )}
          {provider.placeholder}
        </Wrapper>
      )}
    </Droppable>
  );
};

ColumnList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      order: PropTypes.number,
      filename: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  ).isRequired,
  columnId: PropTypes.string.isRequired,
  orderedList: PropTypes.bool.isRequired,
  quantityElements: PropTypes.number.isRequired,
};

ColumnList.defaultOptions = {
  options: [],
  columnId: '',
  orderedList: false,
};

export default ColumnList;