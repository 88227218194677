import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorGeneral from '../ErrorGeneral';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
    });
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorGeneral message={errorMessage} />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

ErrorBoundary.defaultProps = {
};

export default ErrorBoundary;