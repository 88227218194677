import React from 'react';
import { render } from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Honeybadger from 'honeybadger-js';
// import ErrorBoundary from '@honeybadger-io/react';
import TagManager from 'react-gtm-module';

import { StateProvider } from './store';
import '../styles/main.css';
import App from './App';
import theme from './theme';
import './vendor/webgazer';

// const { APP_ENV, HONEYBADGER_API_KEY, REACT_APP_GTM_ID } = process.env;
const { APP_ENV, REACT_APP_GTM_ID } = process.env;


// window.onerror = (msg, url, lineNo, columnNo, error) => {
//   // Honeybadger.notify(error, {
//   //   message: `${msg}, Error: Line: ${lineNo}, Column: ${columnNo}`,
//   //   name: 'GeneralError',
//   //   component: 'Root',
//   //   fingerprint: 'general',
//   //   environment: APP_ENV,
//   //   projectRoot: url,
//   // });
// };

// const config = {
//   api_key: HONEYBADGER_API_KEY,
//   environment: APP_ENV,
//   disabled: APP_ENV === 'development',
//   breadcrumbsEnabled: true,
// };

// const honeybadger = Honeybadger.configure(config);

if (APP_ENV !== 'development' && REACT_APP_GTM_ID) {
  const { REACT_APP_GTM_AUTH, REACT_APP_GTM_ENV } = process.env;

  const tagManagerArgs = {
    gtmId: REACT_APP_GTM_ID,
    auth: REACT_APP_GTM_AUTH,
    preview: REACT_APP_GTM_ENV,
  };

  TagManager.initialize(tagManagerArgs);
}


const Root = () => (
  <StateProvider>
    {/* <ErrorBoundary honeybadger={honeybadger}> */}
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>
    {/* </ErrorBoundary> */}
  </StateProvider>

);

render(<Root />, document.getElementById('app'));
