import Honeybadger from 'honeybadger-js';
import getError from '../components/ErrorApollo/errors';

const errorHandler = ({
  graphQLErrors, networkError, operation, response,
}) => {
  if (graphQLErrors) {
    const { cache } = operation.getContext();
    if (networkError) {
      cache.writeData({
        data: {
          error: {
            open: true,
            type: 'ERROR',
            message: response?.error,
            status: networkError?.statusCode,
          },
        },
      });

      Honeybadger.notify(response?.errors, {
        message: networkError,
        name: 'Graphql Error connection',
        component: 'Apollo client',
        environment: process.env.ENV_HONEYBADGER,
        params: operation.variables,
      });
    }
    const { definitions, loc } = operation.query;
    const errorGQL = graphQLErrors[0];

    const statusCode = errorGQL?.extensions?.code;
    // eslint-disable-next-line no-console
    console.log('statusCode', statusCode);

    cache.writeData({
      data: {
        error: {
          type: 'GQL',
          open: true,
          message: errorGQL.message,
          status: errorGQL?.extensions?.status,
          code: statusCode,
          path: errorGQL.path,
        },
      },
    });

    const error = getError(statusCode);

    if (!error) {
      graphQLErrors.forEach((errorItem, index) => {
        const { message } = errorItem;
        Honeybadger.notify(errorItem[0], {
          message,
          name: `Graphql error ${operation.operationName}`,
          component: 'Apollo client',
          environment: process.env.ENV_HONEYBADGER,
          params: {
            variables: operation.variables,
            query: {
              source: loc.source.body.replace(/\n/g, ''),
              type: definitions[index].kind,
              operation: definitions[index].operation,
              name: operation.operationName,
            },
          },
        });
      });
    }
  }
};


export default errorHandler;