import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconValidationComponent from '../IconValidationComponent';

const PanelValidations = ({
  sunglasses, face, strings, brightness, pose,
}) => (
  <Box mt={2} mb={2}>
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={2}>
        <IconValidationComponent
          active={!sunglasses && face}
          text={strings !== undefined ? strings.sunglases : ''}
          icon="remove_red_eye"
        />
      </Grid>
      <Grid item xs={2}>
        <IconValidationComponent
          active={face}
          text={strings !== undefined ? strings.face : ''}
          icon="face"
        />
      </Grid>
      <Grid item xs={2}>
        <IconValidationComponent
          active={brightness}
          text={strings !== undefined ? strings.lamp : ''}
          icon="wb_incandescent"
        />
      </Grid>
      <Grid item xs={2}>
        <IconValidationComponent
          active={(pose.Yaw && pose.Pitch)}
          text={strings !== undefined ? strings.screen : ''}
          icon="error"
        />
      </Grid>
    </Grid>
  </Box>
);

PanelValidations.propTypes = {
  sunglasses: PropTypes.bool.isRequired,
  face: PropTypes.bool.isRequired,
  brightness: PropTypes.bool.isRequired,
  pose: PropTypes.shape({
    Yaw: PropTypes.bool,
    Pitch: PropTypes.bool,
  }).isRequired,
  strings: PropTypes.shape({
    sunglases: PropTypes.string,
    face: PropTypes.string,
    lamp: PropTypes.string,
    screen: PropTypes.string,
  }),
  classContainer: PropTypes.string,
  classSuccess: PropTypes.string,
};

PanelValidations.defaultProps = {
  strings: undefined,
  classContainer: '',
  classSuccess: 'icon__container_grayopacity',
};

export default PanelValidations;