import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import Honeybadger from 'honeybadger-js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { keyBy } from 'lodash';

import Matrix from './components/Matrix';
import ANSWER_MATRIX from './graphql';
import Question from '../Question';

export class QuestionMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: new Array(props.question.matrix.rows.length),
      disabledNext: true,
      testerid: props.testerid,
      isSending: false,
      dynamicRows: '',
    };
  }

  updateDynamicOptions = () => {
    const { question } = this.props;
    const { rows: matrixRow, multipleQuestionForRowsId } = question.matrix;

    const dynamicAnswers = JSON.parse(localStorage.getItem('dynamic-answers')) || {};
    const currentDynamic = dynamicAnswers[multipleQuestionForRowsId];

    const rowsById = keyBy(matrixRow, 'id');

    const finalRows = currentDynamic ? currentDynamic?.map((rowId) => {
      const currentRow = rowsById[rowId];
      return currentRow;
    }) : matrixRow;

    this.updateState({
      answer: new Array(finalRows.length),
      dynamicRows: finalRows,
    });
  }

  componentDidMount = () => {
    this.updateDynamicOptions();
  }

  componentDidUpdate = (prevProps) => {
    const { question, testerid } = this.props;
    if (question.id !== prevProps.question.id) {
      this.updateState({
        answer: new Array(question.matrix.rows.length),
        disabledNext: true,
        testerid,
        isSending: false,
      });

      this.updateDynamicOptions();
    }
  }

  setAnswer = (value, rowIndex) => {
    const state = { ...this.state };
    const { answer } = state;
    const result = value.split('&&');
    answer[rowIndex] = result;
    const response = answer.filter(row => row !== undefined);
    this.setState({
      answer,
      disabledNext: response.length !== answer.length,
    });
  }

  updateState = (newState) => {
    this.setState(newState);
  }

  next = async (answerMatrix) => {
    const { handlePrompt } = this.props;
    const { answer } = this.state;
    try {
      handlePrompt(false);
      if (!answer.includes(undefined)) {
        this.setState({
          isSending: true,
        }, () => this.realNext(answerMatrix));
      }
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error validation question matrix',
        name: 'next function',
        component: '<QuestionMatrix />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  }

  realNext = async (answerMatrix) => {
    const { testerid, answer } = this.state;
    const { question, nextHandler } = this.props;
    try {
      await answerMatrix({
        variables: {
          tester: testerid,
          question: question.id,
          answer,
        },
      });
      this.setState({
        isSending: false,
      });
      nextHandler(false, null, question.id, question.required);
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error save question matrix',
        name: 'realNext function',
        component: '<QuestionMatrix />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  }

  render() {
    const { question } = this.props;
    const { columns } = question.matrix;
    const { disabledNext, isSending, dynamicRows } = this.state;
    return (
      <Mutation
        mutation={ANSWER_MATRIX}
      >
        { answerMatrix => (
          <Question
            question={question.title}
            image={{
              src: question.imageUrl,
              fileName: question.filename,
            }}
            handleEvent={() => this.next(answerMatrix)}
            disabledNext={disabledNext || isSending}
            loading={isSending}
            mediaFile={question.mediaFile}
          >
            <Box bgcolor="white" boxShadow={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{' '}</TableCell>
                    {
                      columns.map(header => (
                        <TableCell
                          align="center"
                          style={{
                            width: 100, maxWidth: 100, padding: '8px 0',
                          }}
                          key={`$header-col-${header.id}`}
                        >
                          <Typography variant="caption" align="center" gutterBottom>
                            {header.label}
                          </Typography>
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Matrix
                    question={question}
                    setAnswer={this.setAnswer}
                    dynamicRows={dynamicRows}
                  />
                </TableBody>
              </Table>
            </Box>
          </Question>
        )}
      </Mutation>
    );
  }
}

QuestionMatrix.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    matrix: PropTypes.shape({
      columns:
        PropTypes.arrayOf(
          PropTypes.shape(
            {
              label: PropTypes.string,
              id: PropTypes.string,
            },
          ),
        ),
      rows: PropTypes.arrayOf(
        PropTypes.shape(
          {
            label: PropTypes.string,
            id: PropTypes.string,
          },
        ),
      ),
      multipleQuestionForRowsId: PropTypes.string,
    }),
    imageUrl: PropTypes.string,
    filename: PropTypes.string,
    required: PropTypes.bool,
    mediaFile: PropTypes.shape({}),
  }),
  testerid: PropTypes.string,
  nextHandler: PropTypes.func,
  answerMatrix: PropTypes.func,
  isSending: PropTypes.bool,
  handlePrompt: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

QuestionMatrix.defaultProps = {
  question: {},
  testerid: '',
  nextHandler: () => { },
  answerMatrix: () => { },
  isSending: false,
};

export default withTranslation()(QuestionMatrix);
