import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const Container = styled.div`
  background-color: white;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  height: 5.2rem;
`;

const ItemList = ({ title, index, columnId }) => (
  <Draggable
    index={index}
    draggableId={`${index}-${columnId}-${title}`}
  >
    {provider => (
      <Container
        {...provider.draggableProps}
        {...provider.dragHandleProps}
        innerRef={provider.innerRef}
      >
        <Box height="100%" width="100%" borderRadius={2}>
          <Box display="flex" height="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={40}
              bgcolor={columnId === 'listOptions' ? 'primary.main' : 'success.main'}
              color="primary.contrastText"
              borderRadius={2}
            >
              {
                columnId === 'listOptions' ? (
                  <Icon>drag_indicator</Icon>
                ) : (
                  <Typography style={{ color: 'white' }} variant="body1" gutterBottom>
                    {index + 1}
                  </Typography>
                )
              }
            </Box>
            <Box pl={1} display="flex" alignItems="center">
              <Typography variant="body1" gutterBottom>
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    )}

  </Draggable>
);

ItemList.propTypes = {
  columnId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
ItemList.defaultOptions = {
  columnId: '',
  title: '',
  index: 0,
};

export default ItemList;