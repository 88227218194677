import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Container } from '@material-ui/core';

const ContainerPaper = (props) => {
  const { vh, children } = props;
  return (
    <Container maxWidth="lg">
      <Grid container justify="center" alignItems="center" style={{ minHeight: `${vh !== '' ? `${vh}` : '100vh'}` }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Paper elevation={0}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
ContainerPaper.propTypes = {
  children: PropTypes.node.isRequired,
  vh: PropTypes.string,
};

ContainerPaper.defaultProps = {
  vh: '',
};

export default ContainerPaper;