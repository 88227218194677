import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Stepper, Step, StepLabel, Typography,
} from '@material-ui/core';

const StepperComponent = ({ currentStep, steps }) => (
  <Box position="relative" mt={0} mb={3}>
    {steps.length <= 15 ? (
      <Stepper activeStep={currentStep}>
        {steps.map(item => (
          <Step key={item.id}>
            <StepLabel>{' '}</StepLabel>
          </Step>
        ))}
      </Stepper>
    ) : (
      <Typography variant="h2" color="primary">
        {currentStep + 1}
        <Box display="inline-block" mr={1} ml={1}>/</Box>
        {steps.length}
      </Typography>
    )}
  </Box>
);

StepperComponent.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StepperComponent;