import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  icon: {
    fontSize: '4.8rem',
  },
});

const IconValidationComponent = ({
  active,
  text,
  icon,
}) => {
  const classes = useStyles();
  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
      <Box
        width="100%"
        height={98}
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={active ? 'success.main' : 'disabled.main'}
        color={active ? 'primary.contrastText' : 'text.primary'}
        boxShadow={1}
        borderRadius={10}
        mb={1.5}
        data-test="icon-test"
      >
        <Icon className={classes.icon}>{icon}</Icon>
      </Box>
      <Box>
        <Typography align="center" variant="caption" display="block" gutterBottom color="textPrimary">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

IconValidationComponent.propTypes = {
  active: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  styleColorIcon: PropTypes.string,
  styleTextIcon: PropTypes.string,
  text: PropTypes.string,
};

IconValidationComponent.defaultProps = {
  text: '',
  styleTextIcon: '',
  styleColorIcon: '',
};

export default IconValidationComponent;
