import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Snackbar, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ACTIVE_STATUS_NETWORK from './statusNetwork.gql';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const StatusNetWork = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [network, setNetwork] = useState({
    status: 'success',
    message: t('networkStatus.online'),
  });
  let connection = null;

  const { data } = useQuery(ACTIVE_STATUS_NETWORK);

  const updateConnectionStatus = () => {
    if (connection.downlink === 0 || connection.rtt === 0) {
      setNetwork({
        status: 'error',
        message: t('networkStatus.offline'),
      });
      setOpen(true);
      return;
    }
    if (connection.downlink < 1.4 && connection.rtt > 0) {
      setNetwork({
        status: 'warning',
        message: t('networkStatus.slow'),
      });
      setOpen(true);
      return;
    }
    setNetwork({
      status: 'success',
      message: t('networkStatus.online'),
    });
    setOpen(true);
  };

  useEffect(() => {
    if (!connection) {
      connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        connection.onchange = updateConnectionStatus;
      }
    }
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.root}>
      {data.snackBatStatusNetwork && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert severity={network.status}>
            {network.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default StatusNetWork;