import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import store from './global.state';
import errorHandler from './apollo.errorHandler';

const uri = process.env.API_URL;

const errorLink = onError(data => errorHandler(data));

const httpLink = createHttpLink({
  uri,
  credentials: 'same-origin',
  errorLink,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    headers,
  },
}));


const link = ApolloLink.from([
  authLink,
  errorLink,
  httpLink,
]);

const cache = new InMemoryCache({
  addTypename: false,
});

cache.writeData(store);

const client = new ApolloClient({
  link,
  cache,
  resolvers: { /* ... */ },
});

export default client;