import React, { Component } from 'react';
import Honeybadger from 'honeybadger-js';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { Mutation } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import { ColumnList } from '../DraggableComponents';
import ANSWER_IMPORTANCE from './graphql';
import Question from '../Question';

export class QuestionImportance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOptions: [...props.question.importanceOptions],
      listOptionsOrder: [],
      question: { ...props.question },
      testerid: props.testerid,
      isSending: false,
      disabledNext: true,
      quantityElements: [...props.question.importanceOptions].length,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { question, testerid } = this.props;
    if (question.id !== prevProps.question.id) {
      this.updateState({
        listOptions: [...question.importanceOptions],
        listOptionsOrder: [],
        question: { ...question },
        disabledNext: true,
        testerid,
        isSending: false,
        quantityElements: [...question.importanceOptions].length,
      });
    }
  }

  onDragEnd = (result) => {
    const { state } = this;
    const { destination, source, draggableId } = result;
    if (destination && (destination.droppableId === source.droppableId)) {
      const column = state[source.droppableId];
      const draggableIdNew = draggableId.split('-').pop();

      // eslint-disable-next-line react/destructuring-assignment
      const draggableElement = this.props.question.importanceOptions
        .find(columnItem => columnItem.label === draggableIdNew);

      column.splice(source.index, 1);
      column.splice(destination.index, 0, draggableElement);

      const newState = {
        ...state,
        [source.droppableId]: column,
      };
      this.setState(newState);
    } else if (destination && (destination.droppableId !== source.droppableId)) {
      const columnFrom = state[source.droppableId];
      const columnTo = state[destination.droppableId];
      const draggableIdNew = draggableId.split('-').pop();

      // eslint-disable-next-line react/destructuring-assignment
      const draggableElement = this.props.question.importanceOptions
        .find(column => column.label === draggableIdNew);

      const newColumnFrom = columnFrom.filter(column => column.label !== draggableIdNew);
      // columnFrom.splice(source.index, 1);
      columnTo.splice(destination.index, 0, draggableElement);

      const newState = {
        ...state,
        disabledNext: state.quantityElements > columnTo.length,
        [source.droppableId]: newColumnFrom,
        [destination.droppableId]: columnTo,
      };
      this.setState(newState);
    }
  }

  updateState = (newState) => {
    this.setState(newState);
  }

  next = (answerImportance) => {
    try {
      const { handlePrompt } = this.props;
      const { listOptions } = this.state;
      handlePrompt(false);
      if (listOptions.length === 0) {
        this.setState({
          isSending: true,
        }, () => this.realNext(answerImportance));
      }
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error validation question importance',
        name: 'next function',
        component: '<QuestionImportance />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  }

  realNext = async (answerImportance) => {
    const { testerid, question, listOptionsOrder } = this.state;
    const { nextHandler } = this.props;

    const listOptionsOrderIds = listOptionsOrder.map(option => option.id);

    try {
      await answerImportance({
        variables: {
          tester: testerid,
          question: question.id,
          answer: listOptionsOrderIds,
        },
      });
      this.setState({
        isSending: false,
      });
      nextHandler(false, null, question.id, question.required);
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error save question importance',
        name: 'realNext function',
        component: '<QuestionImportance />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  }

  render() {
    const { question } = this.props;
    const {
      disabledNext, isSending, listOptions, listOptionsOrder, quantityElements,
    } = this.state;


    return (
      <Mutation
        mutation={ANSWER_IMPORTANCE}
      >
        { answerImportance => (
          <Question
            question={question.title}
            image={{
              src: question.imageUrl,
              fileName: question.filename,
            }}
            mediaFile={question.mediaFile}
            handleEvent={() => this.next(answerImportance)}
            disabledNext={disabledNext || isSending}
            loading={isSending}
          >
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <ColumnList
                    options={listOptions}
                    orderedList={false}
                    columnId="listOptions"
                    quantityElements={quantityElements}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ColumnList
                    options={listOptionsOrder}
                    orderedList
                    columnId="listOptionsOrder"
                    quantityElements={quantityElements}
                  />
                </Grid>
              </Grid>
            </DragDropContext>
          </Question>
        )}
      </Mutation>
    );
  }
}

QuestionImportance.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired,
    importanceOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        order: PropTypes.number,
        filename: PropTypes.string,
        imageUrl: PropTypes.string,
      }),
    ),
    mediaFile: PropTypes.shape({}),
    imageUrl: PropTypes.string,
    filename: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  testerid: PropTypes.string.isRequired,
  nextHandler: PropTypes.func.isRequired,
  handlePrompt: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default withTranslation()(QuestionImportance);
