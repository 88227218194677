import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

const TitleComponent = (props) => {
  const classes = useStyles();
  const { icon, children } = props;

  const textTransformed = children !== undefined && children.charAt(0)
  + children.slice(1).toLowerCase();

  return (
    <Box className={classes.header}>
      <Box className={classes.iconContainer}>
        <Icon className={classes.icon}>{icon}</Icon>
      </Box>
      <Box>
        <Typography color="primary" variant="h2" className={classes.titleText}>
          {textTransformed}
        </Typography>
      </Box>
    </Box>
  );
};

TitleComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TitleComponent;