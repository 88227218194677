import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
} from '@material-ui/core';
import TitleComponent from '../TitleComponent';
import Button from '../ButtonComponent';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogComponent = ({
  open, icon, title, handleBtn,
  textButton, size, children,
}) => {
  const [openDialog, setOpenDialog] = useState(open);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth={size}
      TransitionComponent={Transition}
      aria-labelledby="dialog-slide-title"
      aria-describedby="dialog-slide-description"
    >
      <DialogTitle id="dialog-slide-title">
        <TitleComponent icon={icon}>{title}</TitleComponent>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {(textButton && handleBtn)
          && (
          <Button
            onClick={() => {
              handleBtn();
              setOpenDialog(false);
            }}
            variant="contained"
            color="positive"
            size="large"
            fullWidth
          >
            {textButton}
          </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

DialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleBtn: PropTypes.func,
  textButton: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  children: PropTypes.node,
};

DialogComponent.defaultProps = {
  children: null,
  handleBtn: null,
};

export default DialogComponent;