import pallete from '../palette';

export default {
  contained: {
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
    backgroundColor: pallete.disabled.contrastText,
    '&:hover': {
      boxShadow: '0px 8px 22px rgba(0, 0, 0, 0.25)',
    },
    '&:disabled': {
      background: pallete.disabled.main,
      color: pallete.disabled.contrastText,
      boxShadow: '0px 8px 22px rgba(0, 0, 0, 0.25)',
    },
  },
  outlined: {
    border: 'none',
    '&:disabled': {
      border: 'none',
    },
    '&:hover': {
      boxShadow: '0px 8px 22px rgba(0, 0, 0, 0.25)',
    },
  },
  outlinedPrimary: {
    border: 'none',
    '&:disabled': {
      border: 'none',
    },
    '&:hover': {
      border: 'none',
      boxShadow: '0px 8px 22px rgba(0, 0, 0, 0.25)',
    },
  },
  outlinedSecondary: {
    border: 'none',
    '&:disabled': {
      border: 'none',
    },
    '&:hover': {
      border: 'none',
      boxShadow: '0px 8px 22px rgba(0, 0, 0, 0.25)',
    },
  },
  sizeLarge: {
    padding: '2rem 1.6rem',
  },
  sizeSmall: {
    padding: '1.2rem 1.6rem',
  },
};