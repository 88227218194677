
const store = {
  data: {
    snackBatStatusNetwork: true,
    error: {
      open: false,
      type: null,
      message: null,
      status: null,
      code: null,
      path: null,
    },
  },
};

export default store;