/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';


const RenderMedia = ({
  mediaType,
  url,
  filename,
  contentType,
  onEnded,
  onPause,
  onPlaying,
}) => {
  if (mediaType === 'image') return <img style={{ maxHeight: '800px', maxWidth: '800px' }} src={url} alt={filename} crossOrigin="anonymous" />;

  if (mediaType === 'video') {
    return (
      <video
        style={{ maxWidth: '800px' }}
        controls="pause/resume volume"
        controlsList="nofullscreen nodownload noProgressBar noplaybackrate"
        className="media_preview"
        src={url}
        crossOrigin="anonymous"
        preload="auto"
        playsinline
        disablePictureInPicture
        disableRemotePlayback
        track
        onEnded={onEnded}
        onPause={onPause}
        onPlaying={onPlaying}
      />
    );
  }

  if (mediaType === 'audio') {
    return (
      <audio
        controls
        controlsList="nofullscreen nodownload noProgressBar noplaybackrate"
        crossOrigin="anonymous"
        onEnded={onEnded}
        onPause={onPause}
        onPlaying={onPlaying}
        src={url}
        type={contentType}
      />
    );
  }

  return <div>Incompatible media file</div>;
};

RenderMedia.propTypes = {
  mediaType: PropTypes.string,
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  onEnded: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onPlaying: PropTypes.func.isRequired,
};


RenderMedia.defaultProps = {
  mediaType: 'image',
};

export default RenderMedia;
