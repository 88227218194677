const styles = () => ({
  paperAnswer: {
    padding: 0,
    height: '100%',
    '& > div > div > label': {
      width: '100%',
      padding: '1.6rem',
    },
    '&:hover': {
      background: '#E7EBEF',
    },
    '&:active': {
      background: '#AFB7E1',
    },
  },
  optionItem: {
    '& > span:last-child': {
      fontSize: '16px !important',
      lineHeight: '20px !important',
    },
  },
  paperAnswerColor: {
    background: '#AFB7E1',
  },
  optionContainer: {
    display: 'flex',
  },
  optionImage: {
    height: '100%',
    width: '100%',
    maxWidth: 120,
    maxHeight: 80,
    objectFit: 'contain',
  },
  optionImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 0%',
  },
  image: {
    width: 120,
    display: 'flex',
    margin: 0,
    flex: 'none',
  },
});

export default styles;