
export default {
  root: {
    padding: '1.6rem',
    boxShadow: 'none',
    background: 'white',
  },
  elevation0: {
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
  },
  elevation1: {
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
  },
  elevation2: {
    boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.25)',
  },
};