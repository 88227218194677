import React from 'react';
import loadable from '@loadable/component';
import Loader from './components/Loader';

const IntroPage = loadable(() => import('./pages/IntroPage'), { fallback: <Loader /> });
const ErrorPage = loadable(() => import('./pages/ErrorPage'), { fallback: <Loader /> });
const QuestionairePage = loadable(() => import('./pages/QuestionnairePage'), { fallback: <Loader /> });
const TaskPage = loadable(() => import('./pages/TaskPage'), { fallback: <Loader /> });
const MediaPage = loadable(() => import('./pages/MediaPage'), { fallback: <Loader /> });
const PermissionPage = loadable(() => import('./pages/PermissionPage'), { fallback: <Loader /> });
const MessagesPage = loadable(() => import('./pages/MessagesPage'), { fallback: <Loader /> });
const ValidationsPage = loadable(() => import('./pages/ValidationsPage'), { fallback: <Loader /> });
const HoneyError = loadable(() => import('./pages/HoneyError'), { fallback: <Loader /> });

const routes = [
  {
    id: 1,
    description: 'router loader thanks',
    path: '/messages/:kind',
    exact: true,
    component: MessagesPage,
  },
  {
    id: 2,
    description: 'router create tester get code and panel',
    path: '/',
    exact: true,
    component: IntroPage,
  },
  {
    id: 3,
    description: 'Just validations interface',
    path: '/intro/:screen',
    exact: true,
    component: IntroPage,
  },
  {
    id: 4,
    description: 'router loader lobby',
    path: '/intro/:screen/:campaignid/:campaigncode/:panelcode/:testerid',
    exact: true,
    component: IntroPage,
  },
  {
    id: 5,
    description: 'Rekognition Calibration',
    path: '/validations/:screen/:campaignid/:testerid',
    exact: true,
    component: ValidationsPage,
  },
  {
    id: 6,
    description: 'router questionnaire profile and exit',
    path: '/question/:typeQuestionnaire/:campaignid/:testerid',
    exact: true,
    component: QuestionairePage,
  },
  {
    id: 7,
    description: 'router questionnaire task',
    path: '/question/:typeQuestionnaire/:campaignid/:testerid/:taskid',
    exact: true,
    component: QuestionairePage,
  },
  {
    id: 8,
    description: 'router list tasks',
    path: '/tasks/:campaignid/:testerid',
    exact: true,
    component: TaskPage,
  },
  {
    id: 9,
    description: 'router show media task',
    path: '/task/media/:campaignid/:taskid/:testerid',
    exact: true,
    component: MediaPage,
  },
  {
    id: 10,
    description: 'permission camera',
    path: '/permission/:campaignid/:testerid',
    exact: true,
    component: PermissionPage,
  },
  {
    id: 11,
    description: 'Honey Badger',
    path: '/honeyerror',
    exact: true,
    component: HoneyError,
  },
  {
    id: 12,
    component: ErrorPage,
  },
];

export default routes;
