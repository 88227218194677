import MuiButton from './MuiButton';
import MuiPaper from './MuiPaper';
import MuiStepper from './MuiStepper';
import MuiStepIcon from './MuiStepIcon';
import MuiSlider from './MuiSlider';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiDialog from './MuiDialog';
import MuiLinearProgress from './MuiLinearProgress';
import MuiButtonGroup from './MuiButtonGroup';

export default {
  MuiStepper,
  MuiButton,
  MuiPaper,
  MuiStepIcon,
  MuiSlider,
  MuiTableCell,
  MuiTableHead,
  MuiDialog,
  MuiLinearProgress,
  MuiButtonGroup,
};