import gql from 'graphql-tag';

const GET_ERROR = gql`
  {
    error @client {
        open
        type
        message
        status
        code
        path
    }
  }
`;

export default GET_ERROR;