import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '../ButtonComponent';

import QuestionTitle from './components/QuestionTitle';
import RenderMedia from './components/RenderMedia';

const Question = (props) => {
  const {
    image, label, question, children, handleEvent,
    disabledNext, loading, questionType, mediaFile,
  } = props;
  const { t } = useTranslation();
  const [isEnded, setIsEnded] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [ispaused, setIspaused] = useState(false);

  const hasMediaFile = mediaFile && mediaFile?.mediaType !== 'image';

  let isDisabled = false;

  if (hasMediaFile) {
    isDisabled = !isEnded;
  }

  let isAlertMessage = isPlayed && ispaused;

  if (!disabledNext && isDisabled) isAlertMessage = true;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center" spacing={1}>
          <Grid item xs={12}>
            <QuestionTitle title={question} />
            <Box mt={1}>
              {
              questionType !== 'MULT_SINGLE'
                && (
                  <Box mb={1}>
                    <Typography variant="body1" color="primary" align="left">{label}</Typography>
                  </Box>
                )
              }
            </Box>
          </Grid>
          { mediaFile && (
            <div>
              <div className="media-question">
                <RenderMedia
                  mediaType={mediaFile.mediaType}
                  url={mediaFile.url}
                  filename={mediaFile.filename}
                  contentType={mediaFile.contentType}
                  onEnded={() => setIsEnded(true)}
                  onPause={() => { setIspaused(true); }}
                  onPlaying={() => { setIsPlayed(true); setIspaused(false); }}
                />
              </div>
              {(!isEnded && mediaFile.mediaType !== 'image') && (
                <div style={{
                  color: isAlertMessage ? '#ff4b4b' : '#9b9c9c', fontSize: 12, textAlign: 'center', marginTop: 10,
                }}
                >
                  Please play the complete media file before answering the question.
                </div>
              )}
            </div>
          )}
          {
            image.src && !mediaFile && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <img
                    style={{ maxHeight: '700px', maxWidth: '800px' }}
                    src={image.src}
                    alt={image.filename}
                  />
                </Box>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="positive"
              onClick={handleEvent}
              disabled={disabledNext || isDisabled}
              loading={loading}
              size="large"
              fullWidth
            >
              {t('general.buttons.next')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  questionType: PropTypes.string,
  label: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    filename: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  handleEvent: PropTypes.func.isRequired,
  disabledNext: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  mediaFile: PropTypes.shape({
    mediaType: PropTypes.string,
    url: PropTypes.string,
    filename: PropTypes.string,
    contentType: PropTypes.string,
  }),
};

Question.defaultProps = {
  label: '',
  image: {},
  questionType: '',
  mediaFile: null,
};

export default Question;