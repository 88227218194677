import { MODAL_SHOW } from './actions/modal.action';

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case MODAL_SHOW: return { ...state, ...action.show };
    default: return state;
  }
};

export default [reducer, initialState];
