import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import graphqlClient from './Apollo/apollo.client';
import StatusNetwork from './components/StatusNetwork';
import ErrorApollo from './components/ErrorApollo';
import routes from './routes';
import './config/i18n';
import { ErrorBoundary } from './components';

const RouterApp = () => (
  <ApolloProvider client={graphqlClient}>
    <StatusNetwork />
    <ErrorBoundary>
      <Router>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.id}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        </Switch>
        <ErrorApollo />
      </Router>
    </ErrorBoundary>
  </ApolloProvider>
);

export default RouterApp;
