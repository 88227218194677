import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@material-ui/core';

const ContainerComponent = (props) => {
  const { vh, children } = props;
  return (
    <Container maxWidth="lg">
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          minHeight: `${vh !== '' ? `${vh}` : '100vh'}`,
        }}
      >
        <Grid item xs={12} sm={12} md={8} lg={8}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

ContainerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  vh: PropTypes.string,
};

ContainerComponent.defaultProps = {
  vh: '',
};

export default ContainerComponent;