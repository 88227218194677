import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

const ButtonComponent = ({
  children, loading, color, variant, ...otherProps
}) => {
  const classes = useStyles();
  const styleVariant = classes[`${variant}-${color}`] || '';
  return (
    <Button
      className={styleVariant}
      {...otherProps}
    >
      {children}
      {
        loading && (
          <Box
            position="absolute"
            style={{
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              opacity: '.5',
            }}
          >
            <CircularProgress className={classes.progress} />
          </Box>
        )
      }
    </Button>
  );
};

ButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  loading: false,
  color: undefined,
  variant: undefined,
};

export default ButtonComponent;
