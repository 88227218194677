import React from 'react';
import { withTranslation } from 'react-i18next';
import Honeybadger from 'honeybadger-js';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import {
  Grid, Paper, Typography, Box, TextField,
} from '@material-ui/core';
import ANSWER_OPEN from './graphql';
import Question from '../Question';

const styles = () => ({
  inputLabel: {
    '& > p': {
      margin: 0,
      marginTop: '8px',
    },
  },
});

export class QuestionOpen extends React.Component {
  constructor(props) {
    super(props);
    const { maximum } = props.question.open;
    this.state = {
      maxCharacter: maximum || 140,
      inputCounter: 0,
      answer: '',
      testerid: props.testerid,
      isSending: false,
      disabledNext: true,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { question, testerid } = this.props;
    if (question.id !== prevProps.question.id) {
      const { maximum } = question.open;
      this.updateState({
        maxCharacter: maximum,
        inputCounter: 0,
        answer: '',
        testerid,
        isSending: false,
        disabledNext: true,
      });
    }
  }

  setAnswer = (e) => {
    const { value } = e.target;
    const { maxCharacter } = this.state;
    const { question } = this.props;
    const { maximum, minimum } = question.open;
    let { inputCounter, disabledNext } = this.state;
    if (value.length > maxCharacter) return;
    if (value.trim().length >= minimum && value.length <= maximum) {
      disabledNext = false;
    } else {
      disabledNext = true;
    }
    inputCounter = value.length;
    this.setState({
      answer: value.replace(/(\r\n|\n|\r)/gm, ''),
      inputCounter,
      disabledNext,
    });
  }

  updateState = (newState) => {
    this.setState(newState);
  }

  next = (answerOpen) => {
    const { handlePrompt } = this.props;
    try {
      handlePrompt(false);
      this.setState({
        isSending: true,
      }, () => this.realNext(answerOpen));
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error validation question open',
        name: 'next function',
        component: '<QuestionOpen />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  }

  realNext = async (answerOpen) => {
    const { testerid, answer } = this.state;
    const { question, nextHandler } = this.props;
    try {
      await answerOpen({
        variables: {
          tester: testerid,
          question: question.id,
          answer,
        },
      });
      this.setState({
        isSending: false,
      });

      const jumpQuestionId = question?.open?.jumpQuestionId;

      nextHandler(false, jumpQuestionId ? [jumpQuestionId] : null, question.id, question.required);
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error save question open',
        name: 'realNext function',
        component: '<QuestionOpen />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  }

  render() {
    const {
      isSending, maxCharacter, answer, inputCounter, disabledNext,
    } = this.state;
    const { question, t } = this.props;
    const { classes } = this.props;
    return (
      <Mutation
        mutation={ANSWER_OPEN}
      >
        {
          answerOpen => (
            <Question
              question={question.title}
              image={{
                src: question.imageUrl,
                fileName: question.filename,
              }}
              mediaFile={question.mediaFile}
              handleEvent={() => this.next(answerOpen)}
              disabledNext={disabledNext || isSending}
              loading={isSending}
            >
              <Paper elevation={0}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-full-width"
                      onChange={this.setAnswer}
                      helperText={`${inputCounter} / ${maxCharacter}`}
                      fullWidth
                      rows="4"
                      margin="none"
                      variant="outlined"
                      multiline
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.inputLabel}
                      maxLength={maxCharacter}
                      value={answer}
                    />
                    <Box mt={-2.5}>
                      <Typography variant="caption" align="right" display="block" gutterBottom>
                        {t('questionOptions.minCharacteres')}
                        {question.open.minimum}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Question>
          )
        }
      </Mutation>
    );
  }
}

QuestionOpen.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    filename: PropTypes.string,
    mediaFile: PropTypes.shape({}),
    open: PropTypes.shape({
      maximum: PropTypes.number,
      minimum: PropTypes.number,
      jumpQuestionId: PropTypes.string,
    }),
    required: PropTypes.bool,
  }).isRequired,
  testerid: PropTypes.string.isRequired,
  nextHandler: PropTypes.func.isRequired,
  handlePrompt: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    inputLabel: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(withTranslation()(QuestionOpen));
