import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

const ButtonGroupComponent = ({
  options, activated, handleClick, ...props
}) => {
  const classes = useStyles();
  return (
    <ButtonGroup {...props} className={options.length <= 2 && classes.hightButton}>
      {options.map(option => (
        <Button
          key={option.id}
          onClick={(e) => {
            e.target.value = option.id;
            handleClick(e);
          }}
          className={
            ` ${activated.includes(option.id) ? classes.activated : ''}`
          }
        >
          <Box display="flex" flexDirection="column" className={classes.answerContainer}>
            {
              (option.imageUrl)
                && (
                  <Box className={
                    `${option.imageUrl === null ? classes.hiddenImage : classes.imageContainer}
                    ${options.length <= 2 && classes.imageContainer2options}`
                    }
                  >
                    <img
                      src={option.imageUrl}
                      alt={option.filename}
                    />
                  </Box>
                )
            }
            <Box className={classes.text}>{option.label}</Box>
          </Box>
        </Button>
      ))}
    </ButtonGroup>
  );
};

ButtonGroupComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activated: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ButtonGroupComponent;