import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Honeybadger from 'honeybadger-js';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { Mutation } from 'react-apollo';
import {
  Grid, Box, Typography, Paper,
} from '@material-ui/core';
import ANSWER_SCALE from './graphql';
import Question from '../Question';

export class QuestionScale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min: props.question.scale.from.value,
      max: props.question.scale.to.value,
      answer: props.question.scale.from.value,
      testerid: props.testerid,
      isSending: false,
      disabledNext: true,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { question, testerid } = this.props;
    if (question.id !== prevProps.question.id) {
      this.updateState({
        question,
        min: question.scale.from.value,
        max: question.scale.to.value,
        answer: 1,
        testerid,
        isSending: false,
        disabledNext: true,
      });
    }
  }

  updateState = (newState) => {
    this.setState(newState);
  }

  handleSlider = (e, value) => {
    this.setState({ answer: value, disabledNext: false });
  }

  next = async (answerScale) => {
    const { handlePrompt } = this.props;
    try {
      handlePrompt(false);
      this.setState({
        isSending: true,
      }, () => this.realNext(answerScale));
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error validation question scale',
        name: 'next function',
        component: '<QuestionScale />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  }

  realNext = async (answerScale) => {
    const { testerid, answer } = this.state;
    const { question, nextHandler } = this.props;
    try {
      await answerScale({
        variables: {
          tester: testerid,
          question: question.id,
          answer: String(answer),
        },
      });
      this.setState({
        isSending: false,
      });
      nextHandler(false, null, question.id, question.required);
    } catch (error) {
      Honeybadger.notify(error, {
        message: 'Error save question scale',
        name: 'realNext function',
        component: '<QuestionScale />',
        fingerprint: 'QuestionnaireContainer',
        environment: process.env.ENV_HONEYBADGER,
      });
    }
  }

  render() {
    const { question } = this.props;
    const {
      disabledNext, isSending, min, max, answer,
    } = this.state;
    return (
      <Mutation
        mutation={ANSWER_SCALE}
      >
        { answerScale => (
          <Question
            question={question.title}
            image={{
              src: question.image_url,
              fileName: question.filename,
            }}
            mediaFile={question.mediaFile}
            handleEvent={() => this.next(answerScale)}
            disabledNext={disabledNext || isSending}
            loading={isSending}
          >
            <Paper>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Box mt={3} ml={0.5} width="98%">
                    <Slider
                      id="slider"
                      defaultValue={min}
                      getAriaValueText={value => value}
                      aria-labelledby="discrete-slider-always"
                      step={1}
                      marks={Array(max).fill('').map((item, index) => ({ value: index + 1 }))}
                      valueLabelDisplay="on"
                      onChange={this.handleSlider}
                      min={min}
                      max={max}
                      value={answer}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" gutterBottom>
                      {question.scale.from.label}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {question.scale.to.label}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Question>
        )}
      </Mutation>
    );
  }
}

QuestionScale.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    scale: PropTypes.shape({
      from: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
      to: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
    mediaFile: PropTypes.shape({}),
    image_url: PropTypes.string,
    filename: PropTypes.string,
  }).isRequired,
  testerid: PropTypes.string.isRequired,
  nextHandler: PropTypes.func.isRequired,
  handlePrompt: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(QuestionScale);