import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enStrings from './languages/en-strings';
import esStrings from './languages/es-strings';
import ptStrings from './languages/pt-strings';

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: enStrings,
      es: esStrings,
      pt: ptStrings,
    },
    lng: localStorage.getItem('lang') || 'en',
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18next;