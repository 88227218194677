import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';

const renderMatrix = ({ question, setAnswer, dynamicRows }) => {
  const { columns, rows } = question.matrix;

  const currentRows = dynamicRows || rows;

  return currentRows.map((row, rowIndex) => (
    <TableRow key={`${row.id}-${question.id}`}>
      <TableCell style={{ borderBottom: '1px solid #E7EBEF' }} component="th" scope="row">
        {/* {row.imageUrl && row.filename ? (
          <img
            src={row.imageUrl}
            alt={row.label}
            style={{
              width: 150, height: 150, objectFit: 'contain', objectPosition: 'center',
            }}
          />
        ) : undefined} */}
        <Typography variant="body2" gutterBottom>
          {row.label}
        </Typography>
      </TableCell>
      <TableCell style={{ borderBottom: '1px solid #E7EBEF', padding: 0 }} align="center" colSpan={columns.length}>
        <RadioGroup
          onChange={(e, value) => setAnswer(value, rowIndex)}
        >
          <Table>
            <TableBody>
              <TableRow>
                {
                  columns.map(col => (
                    <TableCell
                      key={`${col.id}-${question.id}`}
                      align="center"
                      style={{
                        width: 100, maxWidth: 100, padding: 0,
                      }}
                    >
                      <Box height="100%" width="100%" display="flex" justifyContent="flex-end" alignItems="center">

                        <FormControlLabel
                          style={{
                            width: '100%', display: 'flex', justifyContent: 'center', margin: 0,
                          }}
                          value={`${row.id}&&${col.id}`}
                          control={<Radio name={`${question.id}${row.label}&&${col.label}`} color="primary" />}
                        />

                      </Box>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableBody>
          </Table>
        </RadioGroup>
      </TableCell>
    </TableRow>
  ));
};

export default renderMatrix;