import palette from '../palette';

export default {
  root: {
    background: palette.primary.contrastText,
  },
  contained: {
    border: palette.primary.main,
  },
  groupedOutlinedPrimary: {
    border: `1px solid ${palette.primary.main} !important`,
    '&:hover': {
      boxShadow: 'none',
      color: palette.primary.main,
    },
  },
};