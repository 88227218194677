import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

const Loader = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    width="100%"
    minHeight="100vh"
  >
    <Box width="100%">
      <Grid container spacing={1} justify="center">
        <Grid item xs={8}>
          <Box
            p={2}
            height={400}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Skeleton height={60} width="100%" />
            <Skeleton height={60} width="100%" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

export default Loader;