export default {
  translations: {
    general: {
      buttons: {
        next: 'next',
        help: 'help',
        notAllow: "Don't allow",
        select: 'select',
        startTask: 'start task',
        continueStudy: 'Continue Study',
        finishStudy: 'Finish Study',
        back: 'back',
        accept: 'accept',
      },
    },
    prompt: {
      message: 'You have unsaved changes, are you sure you want to leave?',
    },
    intro: {
      welcome: {
        title: 'Welcome!',
        subtitle:
          'You are about to participate in an anonymous market survey. Please keep in mind:',
        lists: [
          `1. The survey consists of one or more tasks where you will interact with content.`,
          `2. All the data we collect is anonymous and private.`,
        ],
        textPromotional: 'I confirm I am over 18 years old, and I have read the following ',
        privacity: '',
        research: 'Research and Consent Form.',
        tc: 'Terms and Conditions ',
        linkTerms: 'https://www.synapbox.com/terms-and-conditions',
        linkPrivacity: 'https://www.synapbox.com/participants-consent-us/',
        lastText: ' and I have read',
        pp: ' Privacy Policies',
        btnStart: 'START',
        chromeComputer: 'To complete this study, you must open it using a computer.',
        otherBrowserComputer: 'To complete this study, you must open it using a computer and Google Chrome browser, you can download it clicking ',
        otherBrowserMobile: 'To complete this study, you must open it using a mobile phone and Google Chrome browser, you can download it clicking ',
        otherBrowser: 'To complete this study, you must open it using Google Chrome browser, you can download it clicking ',
        anchorText: 'here',
        linkChrome: 'https://www.google.com/chrome/',
      },
      lobby: {
        title: 'Thanks for your time, now tell us about you!',
        subtitle: 'Please answer the following questions.',
      },
      validations: {
        title: 'Please follow the tips below to successfully complete the evaluation',
        titleBar: 'In a few seconds, we will start the evaluation and your web camera will be turned on.',
        sunglases: 'Do not wear sunglasses',
        face: 'Place your face in front of the camera',
        lamp: 'Ensure you are in good lighting',
        screen: 'Pay attention to the screen',
      },
      calibration: {
        title: 'New title',
      },
      thanks: {
        title: 'THANKS FOR YOUR TIME.',
        titlePaused: 'THANK YOU VERY MUCH FOR YOUR INTEREST.',
        titleErrorInit: 'THE LINK YOU USED TO ENTER THIS STUDY IS NOT VALID',
        titleErrorUnknow: 'AN UNEXPECTED ERROR HAS OCCURR',
        pharagraph:
          'This study is now already complete as the desired number of participants was reached. We invite you to participate in future evaluations.',
        pharagraphTerminate:
          'Your profile does not meet the requirements to continue with this study.',
        pharagraphPaused: 'This evaluation is currently paused. Try again in a couple of hours.',
        pharagraphClosed: 'This evaluation has been closed.',
        pharagraphFinallyPaused: 'Feel free to close this window.',
        pharagraphError:
          'Please click on the following link to get in touch with our support team:',
        pharagraphErrorUnknown: 'Please click on the following link to start again:',
        pharagraphFinally: 'Feel free to close this window.',
        pharagraphFinallyError: 'Thank you!',
        pharagraphCompleted: 'The evaluation has been completed. Your participant ID is: ',
        emailSOS: 'sos@synapbox.com',
        linkText: 'https: //gate.synapbox.com/?cc=WDnsdu&amp;pc=AWS',
      },
    },
    questionOptions: {
      simple: 'You can choose an option',
      multiple: 'You can choose more than one option',
      messageHeader: 'Please answer all the questions',
      finishQuestionnaire: 'You have already finished the questionnaire',
      minCharacteres: 'Minimum characters required:',
      errors: {
        boxEmpty: 'You have to write something',
        minCharacteres: 'You should write something longer',
        selectEmpty: 'You have to select any option',
        orderNotFinish: 'You have to drag and drop all items in right column',
      },
      scaleQuestion: {
        leftRating: 'Totally disagree',
        rightRating: 'Totally agree',
        errors: {
          sliderNotTouched: 'You have to touch and move the slider one time',
        },
      },
      importanceQuestion: {
        dragMessage: 'Drag and drop to this section the options in order as you want',
      },
    },
    errorPage: {
      title: 'Sometimes getting lost isn’t so bad…',
      titleHeader: 'Error page - Error code 404',
      errorTitle: 'ERROR CODE',
      errorCode: '404',
      errorContent: 'The page you requested was removed, renamed or doesn’t exists.',
      errorBtnBack: 'Back to my campaigns',
      errorGeneralBtnBack: 'Back',
      errorGeneralTitle: 'Sorry',
      errorGeneralText: 'Looks like something went wrong.',
    },
    tasks: {
      title: 'Tasks to be completed',
      subtitle: 'You have to complete all the tasks to complete the study correctly',
      time: 'Time to complete the task:',
      instruction: 'Instruction:',
      success: 'You have successfully completed all tasks and the results were sent correctly.',
      continue: 'Continue to your panel page and conclude your participation.',
      thanks: 'Thanks for your time!',
    },
    taskMedia: {
      help: 'help',
      content: 'Content:',
      ContentImage: 'Image',
      ContentVideo: 'Video',
      task: 'Task',
      loadingContent: 'Loading content',
      loadingContentValidation: 'Validating your attention in the last task',
      browserErrorTag: 'Your browser does not support the video tag.',
      loadingTitle: "We are sending your results to our servers. This will only take a couple of seconds, please don't close this window…",
    },
    permissions: {
      title: 'In order to continue we need access to your webcam.',
      message: "It is essential that you allow us to use your computer's webcam",
      tips: [
        '1. We need your webcam to register your interaction with the content.',
        '2. All collected information is anonymous and your identity will not be analyzed or stored.',
        '3. Once the study is complete or this tab is closed, the camera will turn off automatically and the study will end.',
      ],
      button: {
        accept: 'ALLOW USE OF WEBCAM',
      },
    },
    modal: {
      problemValidation: 'There is a problem with the sensor',
      titleErrorNoFace: 'Problems with the sensors',
      noFaceDetection: "We don't recognize your face, please try again",
      noMedia: "The task doesn't have media content",
      noAcceptPermission:
        'You have to accept the camera permissions, check the permissions of your camera and reload the page',
      noCameraPermission:
        'No camera permissions, check the permissions of your camera and reload the page',
    },
    calibration: {
      eyeCalibration: {
        title: 'Calibration',
        tip1: '1. Click each orange button 5 times till it goes green',
        tip2: '2. Stare at the middle dot for 5 seconds without moving mouse',
        btn: 'OK'
      },
      measurement: {
        title: 'Calculating Measurement',
        message: 'After clicking OK, please stare at the middle dot for 5 seconds and don\'t move your mouse.',
        btn: 'Ok',
      },
      initial: {
        title: 'Steps to Calibrate Your Webcam',
        message: [
          '1 - Place your face inside the red square and move it around until it turns green.',
          '2 - When a mask appears on your face and the square is green, you can click on ‘BEGIN CALIBRATION’.',
        ],
        btn: 'BEGIN CALIBRATION',
      },
      complete: {
        title: 'Calibration Completed',
        message: 'Click OK to start watching the content.',
        btn: 'OK',
      },
      error: {
        title: 'You have to repeat the task since some conditions were not met.',
        message:
          'Please make sure to 1) face the screen at all times; 2) avoid moving your head abruptly; 3) have proper lighting conditions; 4) and do not wear hats or sunglasses.',
      },
    },
    validation: {
      modal: {
        title: 'Successful validation.',
        message: 'You can continue with the study. ',
      },
      browser: {
        type: 'This study is only available for the Google Chrome browser.',
        version:
          'The Google Chrome version must be equal to or later than version 72. Please update Chrome to complete this study.',
        platform:
          'This study can not be completed from a cell phone or tablet. Please use a laptop or desktop.',
      },
    },
    networkStatus: {
      online: 'Your device is connected to the Internet',
      slow: 'Your Internet connection is slow',
      offline: 'Your device lost it is internet connection',
    },
    dialogPlayVideo: {
      title: 'We are ready',
      message: 'Click on the green button to start',
      button: 'Play content',
    },
    errorValidateTester: {
      title: 'Error',
      text: 'We are having trouble checking the task, check your internet connection and try again',
      button: 'Try again'
    },
    testerAlreadyFiltered: {
      title: 'Notice',
      text: 'You have already been filtered, you cannot continue',
    },
  },
};
